<template>
  <div>
    <b-row>
      <b-col
        v-for="(item, index) in filteredItems"
        :key="index"
      >
        <b-card
          style="height: 150%;"
          :img-src="item.imagem"
          class="card-hover"
          @click="openModal(item.link)"
        >
          <div class="text-center">
            <h4
              :class="{ 'title-hover': isHovering }"
              @mouseenter="hoverIn"
              @mouseleave="hoverOut"
            >{{ item.titulo }}</h4>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="modalViewlink"
      size="xl"
      title="Visualizar Relatorio"
      ok-only
      :modal-class="'custom-modal'"
    >
      <div>
        <iframe
          :src="link"
          frameborder="0"
          style="width: 100%; height: 95vh"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

import axios from '@/../axios-auth'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      isHovering: false,
      items: [],
      link: '',
      fields: [
        { key: 'titulo', label: 'TITULO', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
    }
  },
  computed: {
    filteredItems() {
      const funcao = localStorage.getItem('funcao')
      return this.items.filter(item => {
        if (!item.departamentos) return false
        const departamentos = JSON.parse(item.departamentos)
        return departamentos.some(departamento => departamento.label === funcao)
      })
    },
  },
  created() {
    this.getLinks()
  },
  methods: {
    hoverIn() {
      this.isHovering = true
    },
    hoverOut() {
      this.isHovering = false
    },
    getLinks() {
      axios.get('api/v1/link_powerbi/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(item => {
            this.items.unshift(item)
          })
        })
    },
    openModal(link) {
      this.link = link
      this.$refs.modalViewlink.show()
    },
  },
}
</script>
<style>
.custom-modal .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}
.custom-modal .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
}
.custom-modal .modal-body {
  height: calc(100% - 38px); /* 38px é a altura do botão do modal */
  overflow-y: auto;
}
.custom-modal .modal-body iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.shadow-hoverable {
  transition: box-shadow 0.3s;
}

.shadow-hoverable:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.card-hover:hover {
  cursor: pointer;
  background-color: #fd8426;
}
.card-hover:hover h4 {
  color: #ffffff;
}
</style>
